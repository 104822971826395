import React from "react";
import Helmet from "react-helmet";
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import ContactBar from '../components/ContactBar'
import { graphql } from 'gatsby';

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  return (
    <Layout location={location}>
      <div className="blog-post-container page with-sidebar">
        <Helmet title={`Paola Nakahara - ${post.frontmatter.title}`} />
        <ContactBar className="large-screen" />
        <div className="container blog-post">
          <div className="content">
            <h1>{post.frontmatter.title}</h1>
            <div className="meta">
              <span className="author">{post.frontmatter.author}</span>
              <span className="date">{post.frontmatter.date}</span>
              <span className="category">{post.frontmatter.categories}</span>
            </div>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </div>
          <Sidebar />
        </div>
      </div>
    </Layout>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "dddd, Do MMMM YYYY", locale: "de")
        path
        title
        author
        categories
      }
    }
  }
`;



